import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

/** @jsx jsx */
import { css, jsx } from '@emotion/core';

function TrefleSearch({
  search,
  change,
}: InferProps<typeof TrefleSearch.propTypes>): JSX.Element {
  return (
    <div className="max-w-5xl mx-auto my-12 px-6">
      <div className="bg-white">
        <form className="flex w-full p-6">
          <input
            id="plant-name"
            placeholder="Plant name"
            onChange={change}
            css={courierPrime}
            className="mr-4 form-input block border-b-2 border-gray-600 w-full focus:outline-none focus:border-b-2 transition duration-150"
          />
          <button
            type="submit"
            onClick={search}
            css={courierPrime}
            className="w-32 inline-flex items-center justify-center px-3 py-2 border border-black leading-4 font-medium text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50 transition ease-in-out duration-150"
          >
            Search
          </button>
        </form>
      </div>
    </div>
  );
}

TrefleSearch.propTypes = {
  search: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
};

const courierPrime = css`
  font-family: 'Courier Prime', sans-serif;
`;

export default TrefleSearch;
