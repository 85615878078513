import React from 'react';

import { useAuth0 } from '@auth0/auth0-react';

const Profile = (): JSX.Element => {
  const { isAuthenticated, user } = useAuth0();
  if (isAuthenticated) {
    const { name, email } = user;
    return (
      <div>
        <div>
          <div>
            <h2>{name}</h2>
            <p className="lead text-muted">{email}</p>
          </div>
        </div>
      </div>
    );
  }
  return <div>Not Auth</div>;
};

export default Profile;
