// eslint-disable-next-line
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

/** @jsx jsx */
import { css, jsx } from '@emotion/core';

import LoginButton from './LoginButton';
import LogoutButton from './LogoutButton';

const NavBar = (): JSX.Element => {
  const { isAuthenticated, user } = useAuth0();

  return (
    <React.Fragment>
      <nav className="w-full border-b-2 border-black">
        <div className="max-w-6xl mx-auto grid lg:grid-cols-3 grid-cols-1 justify-between lg:py-8 p-6 w-full">
          <div className="col-span-1 flex items-center lg:justify-start justify-center lg:order-1 order-2 space-x-3 my-6">
            <NavLink
              to="/flowers"
              activeClassName="border-black"
              className="border-b-2 border-transparent text-black no-underline"
            >
              Flowers
            </NavLink>
            <NavLink
              to="/trefle"
              activeClassName="border-black"
              className="border-b-2 border-transparent text-black no-underline"
            >
              Trefle
            </NavLink>
          </div>
          <div
            className="col-span-1 flex items-center justify-center lg:text-3xl text-xl lg:order-2 order-1"
            css={logo}
          >
            <NavLink
              exact
              to="/"
              className="text-black transition duration-150"
              activeClassName="text-green-600"
            >
              Good To Plant!
            </NavLink>
          </div>
          <div className="col-span-1 flex items-center lg:justify-end justify-center order-3 space-x-3">
            {isAuthenticated ? <LogoutButton /> : <LoginButton />}
            {isAuthenticated && (
              <img src={user.picture} alt="user" css={avatar} />
            )}
          </div>
        </div>
      </nav>
    </React.Fragment>
  );
};

const nav = css``;

const logo = css`
  font-family: 'Authentic', sans-serif;
  font-weight: normal;
`;

const avatar = css`
  margin-right: 12px;
  border-radius: 100%;
  width: 3em;
  height: 3em;
`;

export default NavBar;
