import React from 'react';

import {
  createHttpLink,
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuth0 } from '@auth0/auth0-react';

const GRAPHQL_URL = `https://${process.env.REACT_APP_HASURA_ENDPOINT}/v1/graphql`;

const ApolloProviderWithClient = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const { getIdTokenClaims } = useAuth0();

  const httpLink = createHttpLink({
    uri: GRAPHQL_URL,
  });

  const authLink = setContext(async () => {
    const claim = await getIdTokenClaims();
    return {
      headers: {
        ...(claim ? { Authorization: `Bearer ${claim.__raw}` } : {}), // eslint-disable-line no-underscore-dangle
      },
    };
  });

  // chose the link to use based on operation

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloProviderWithClient;
