import React from 'react';

/** @jsx jsx */
import { css, jsx } from '@emotion/core';

type Plant = {
  __typename: string;
  id: string;
  common_name: string;
  scientific_name: string;
  image_url: string;
  year: number;
  bibliography: string;
  author: string;
  family_common_name: string;
  genus_id: number;
  observations: string;
  vegetable: string;
  genus: string;
  family: string;
  edible: string;
  edible_part: string;
};

const TrefleCard = ({
  data,
  onClick,
}: {
  data: Plant;
  onClick: () => void;
}): JSX.Element => (
  <div className="flex flex-col justify-between bg-white p-6">
    <img
      className="mb-8 h-full w-full object-cover"
      css={image}
      src={
        data.image_url ||
        'https://via.placeholder.com/300x300?text=Good+To+Plant'
      }
      alt={`${data.common_name}`}
    />
    <div>
      <h4 className="text-xl leading-5 pb-2 mb-2 capitalized border-b border-black">
        {data.common_name || data.scientific_name}
      </h4>
      <div className="flex flex-col text-sm mb-4">
        <p>
          <span css={courierPrime} className="text-xs">
            Year:
          </span>
          <br />
          {data.year}
        </p>
        <p>
          <span css={courierPrime} className="text-xs">
            Vegetable:
          </span>
          <br />
          {data.vegetable ? 'Yes' : 'No'}
        </p>
        <p>
          <span css={courierPrime} className="text-xs">
            Edible:
          </span>
          <br />
          {data.edible ? 'Yes' : 'No'}
        </p>
        <p>
          <span css={courierPrime} className="text-xs">
            Family Common Name:
          </span>
          <br />
          {data.family_common_name}
        </p>
        <p>
          <span css={courierPrime} className="text-xs">
            Genus:
          </span>
          <br /> {data.genus}
        </p>
      </div>
      <button
        type="button"
        css={courierPrime}
        className="w-full inline-flex items-center bg-transparent focus:outline-none tracking-tight text-sm border-b border-transparent hover:border-black transition duration-150"
        onClick={onClick}
      >
        <span className="ml-auto">+ Add to Collection</span>
      </button>
    </div>
  </div>
);

export default TrefleCard;

const image = css`
  max-height: 400px;
`;

const courierPrime = css`
  font-family: 'Courier Prime', sans-serif;
`;
