import React from 'react';
import { Global, css } from '@emotion/core';

const global = css`
  @import url('https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap');
  body {
    background-color: #ededed;
  }
  * {
    font-family: 'Authentic', sans-serif;
  }
`;
const Layout = ({ children }: { children: React.ReactNode }): JSX.Element => {
  return (
    <>
      <Global styles={global} />
      {children}
    </>
  );
};

export default Layout;
