import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

/** @jsx jsx */
import { css, jsx } from '@emotion/core';

import Loading from '../components/status/Loading';
import Error from '../components/status/Error';
import TrefleCard from '../components/trefle/TrefleCard';
import TrefleSearch from '../components/trefle/TrefleSearch';

type TrefleMeta = {
  total?: number;
};

type TrefleLinks = {
  first: string;
  next: string;
  prev: string;
  last: string;
};

type Plant = {
  __typename: string;
  id: string;
  common_name: string;
  scientific_name: string;
  image_url: string;
  year: number;
  bibliography: string;
  author: string;
  family_common_name: string;
  genus_id: number;
  observations: string;
  vegetable: string;
  genus: string;
  family: string;
  edible: string;
  edible_part: string;
};

const Trefle = (): JSX.Element => {
  const history = useHistory();
  const location = useLocation();

  const [search, setSearch] = useState<string>('');
  const [data, setData] = useState<Plant[]>([]);
  const [meta, setMeta] = useState<TrefleMeta | undefined>(undefined);
  const [links, setLinks] = useState<TrefleLinks | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);

  // const [addCollection] = useMutation(ADD_TO_COLLECTION);
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get('q');
    const page = searchParams.get('page');

    let uri: string;

    if (query && page) {
      uri = `/api/v1/plants/search?q=${query}&page=${page}&`;
    } else if (query) {
      uri = `/api/v1/plants/search?q=${query}&`;
    } else {
      uri = '/api/v1/plants?';
    }

    fetchTrefle(uri);
  }, [location]);

  const fetchTrefle = async (query: string): Promise<void> => {
    try {
      setLoading(true);
      const res = await fetch('/api/trefle', {
        method: 'post',
        body: JSON.stringify({ query }),
      });
      const body = res;
      const json = await body.json();
      setMeta(json.body.meta);
      setData(json.body.data);
      setLinks(json.body.links);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: Event): void => {
    const target = e.target as HTMLTextAreaElement;
    setSearch(target.value);
  };

  const handleSearch = (e: Event): void => {
    e.preventDefault();
    history.push({
      pathname: '/trefle',
      search: `?q=${search}`,
    });
  };

  const select = (): void => {
    // addCollection({ variables: { trefle_id: id } });
  };

  const navigatePage = (link: string): void => {
    const page = link.match(/(?<=page=).*?(?=&)/) || '';
    const query = link.match(/(?<=q=).*/) || '';
    history.push({
      pathname: '/trefle',
      search: `?page=${page[0]}&q=${query[0]}`,
    });
  };

  return (
    <React.Fragment>
      <TrefleSearch change={handleChange} search={handleSearch} />
      {meta && <p className="text-center my-8">Total: {meta.total || 'n/a'}</p>}
      {loading && <Loading />}
      {error && <Error />}
      {data && (
        <div className="max-w-6xl mx-auto grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-10 px-6">
          {data.map(
            (item: Plant): JSX.Element => (
              <TrefleCard key={item.id} data={item} onClick={() => select()} />
            ),
          )}
        </div>
      )}
      {links && (
        <div className="max-w-xl mx-auto flex justify-between my-6 px-12">
          <button
            type="button"
            css={courierPrime}
            className="text-black w-5 h-5"
            onClick={() => navigatePage(links.first)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M11 19l-7-7 7-7m8 14l-7-7 7-7"
              />
            </svg>
          </button>
          <button
            type="button"
            css={courierPrime}
            className="text-black w-5 h-5"
            onClick={() => navigatePage(links.prev)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          <button
            type="button"
            css={courierPrime}
            className="text-black w-5 h-5"
            onClick={() => navigatePage(links.next)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
          <button
            type="button"
            css={courierPrime}
            className="text-black w-5 h-5"
            onClick={() => navigatePage(links.last)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 5l7 7-7 7M5 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>
      )}
    </React.Fragment>
  );
};

const courierPrime = css`
  font-family: 'Courier Prime', sans-serif;
`;

export default Trefle;
