import React from 'react';
import { useQuery, gql } from '@apollo/client';

const GET_FLOWERS = gql`
  query GetFlowers {
    flowers {
      id
      common_name
    }
    users {
      id
    }
  }
`;

interface Flower {
  id: string;
  common_name: string;
}

const Flowers = (): JSX.Element => {
  const { loading, error, data } = useQuery(GET_FLOWERS);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  return data.flowers.map(({ id, common_name }: Flower) => (
    <div key={id}>
      <p>{common_name}</p>
    </div>
  ));
};

export default Flowers;
