import React from 'react';

import './assets/main.css';
import './assets/global.css';

import { Route, Switch } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { NavBar } from './components';
import { Home, Profile, Flowers, Trefle } from './views';
import Layout from './components/Layout';

const App = (): JSX.Element => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading</div>;
  }
  return (
    <div id="app" className="d-flex flex-column h-100">
      <Layout>
        <NavBar />
        <div>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/profile" component={Profile} />
            <Route path="/flowers" component={Flowers} />
            <Route path="/trefle" component={Trefle} />
          </Switch>
        </div>
      </Layout>
    </div>
  );
};

export default App;
