import React from 'react';

const Home = (): JSX.Element => {
  return (
    <div>
      <h1>Empty Home... lol</h1>
    </div>
  );
};

export default Home;
