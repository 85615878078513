import React from 'react';
import { useHistory } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

interface IProps {
  children: string | JSX.Element;
  // any other props that come into the component
}

const Auth0ProviderWithHistory = ({ children }: IProps) => {
  const domain: string = process.env.REACT_APP_AUTH0_DOMAIN || '';
  const clientId: string = process.env.REACT_APP_AUTH0_CLIENT_ID || '';

  const history = useHistory();

  const onRedirectCallback: any | undefined = (appState: { returnTo: any }) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
