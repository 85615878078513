import React from 'react';
import { useQuery, gql } from '@apollo/client';

const GET_PLANTS = gql`
  query GetPlants {
    plants {
      id
      common_name
    }
  }
`;

interface Plant {
  id: string;
  common_name: string;
}

const Plants = (): JSX.Element => {
  const { loading, error, data } = useQuery(GET_PLANTS);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;
  return data.plants.map(({ id, common_name }: Plant) => (
    <div key={id}>
      <p>{common_name}</p>
    </div>
  ));
};

export default Plants;
